import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class ProjectsList extends ViewBasic {
    constructor(options) {
        super(options);

        this.currentFilterValue = '';
        this.filters = this.el.querySelectorAll('.filter');
        this.displayedValues = this.el.querySelectorAll('.list-value');
        this.classes = {
            hide: 'hide',
        };
        this.projectWrappers = this.el.querySelectorAll(
            '.project-list-wrapper',
        );
        this.ctaWidth = this.el.querySelector('.arrow-cta')?.offsetWidth;
        this.containerMargin =
            (window.innerWidth -
                this.el.querySelector('.centered-content').offsetWidth) /
            2;
        this.thumbnail = this.el.querySelector('.thumbnail');

        this.mouse = null;
        this.pos = null;
        this.xSet = null;
        this.ySet = null;
    }

    initialize() {
        this.bindAll([
            'changeHandler',
            'updateThumbnailImage',
            'updateThumbnailCoordinates',
            'mouseMoveEvent',
            'showThumbnailEvent',
            'hideThumbnailEvent',
            'resizeUpdates',
        ]);

        this.pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        this.xSet = gsap.quickSetter(this.thumbnail, 'x', 'px');
        this.ySet = gsap.quickSetter(this.thumbnail, 'y', 'px');

        this.addEvents();

        //Added to support #hash to preselect a filter (used mainly in articles and the blog)
        if(window.location.hash) {
            // Fragment exists
            this.filters.forEach((element) => {
                //find the hash as a value of one of the filter
                if(element.value.indexOf(window.location.hash.replace('#','')) != -1){
                    //trigger click and select the filter
                    element.click();
                    setTimeout(function(){
                        //scroll to the filter
                        window.lenis.scrollTo(element.getBoundingClientRect().top, {
                            offset: -100,
                            duration: 1,
                            easing: function easeInOutSine(x) {
                                return -(Math.cos(Math.PI * x) - 1) / 2;
                            }
                        });
                    },100);

                }
            });
        }
    }

    addEvents() {
        this.filters.forEach((element) => {
            element.addEventListener('change', this.changeHandler);
        });
        this.projectWrappers.forEach((projectWrapper) => {
            // Put all images in the hover thumbnail to preload them
            let image = document.createElement('img');
            image.src =
                projectWrapper.querySelector('.project-wrapper').dataset.image;
            image.setAttribute('loading', 'lazy');
            image.setAttribute('alt', '');
            this.thumbnail.appendChild(image);

            projectWrapper.addEventListener(
                'mouseenter',
                this.updateThumbnailImage,
            );
            projectWrapper.addEventListener(
                'mouseenter',
                this.showThumbnailEvent,
            );
            projectWrapper.addEventListener('focusin', this.showThumbnailEvent);

            projectWrapper.addEventListener(
                'mouseleave',
                this.hideThumbnailEvent,
            );
            projectWrapper.addEventListener(
                'focusout',
                this.hideThumbnailEvent,
            );
        });

        // Mouse move for thumbnail
        gsap.set(this.thumbnail, { xPercent: -50, yPercent: -50 });
        this.mouse = { x: this.pos.x, y: this.pos.y };

        window.addEventListener('mousemove', this.mouseMoveEvent);
        window.addEventListener('resize', this.resizeUpdates);
        gsap.ticker.add(this.updateThumbnailCoordinates);
    }

    removeEvents() {
        this.filters.forEach((element) => {
            element.removeEventListener('change', this.changeHandler);
        });
        this.projectWrappers.forEach((projectWrapper) => {
            projectWrapper.removeEventListener(
                'mouseenter',
                this.updateThumbnailImage,
            );
            projectWrapper.removeEventListener(
                'mouseenter',
                this.showThumbnailEvent,
            );
            projectWrapper.removeEventListener(
                'focusin',
                this.showThumbnailEvent,
            );
            projectWrapper.removeEventListener(
                'mouseleave',
                this.hideThumbnailEvent,
            );
            projectWrapper.removeEventListener(
                'focusout',
                this.hideThumbnailEvent,
            );
        });
        window.removeEventListener('mousemove', this.mouseMoveEvent);
        window.removeEventListener('resize', this.resizeUpdates);
        gsap.ticker.remove(this.updateThumbnailCoordinates);
    }

    changeHandler(e) {
        this.currentFilterValue = e.currentTarget.value;

        // Send to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'filters-projects',
            filter_name: e.currentTarget.nextElementSibling.textContent.trim(),
        });

        this.updateDisplayed();
    }

    updateDisplayed() {
        this.displayedValues.forEach((element) => {
            if (this.currentFilterValue === '') {
                element.classList.remove(this.classes.hide);
            } else {
                //find the selected filter in the value of the item value. Since the item could have more than 1 value, we ook for the filter inside the whole value and find a match.
                if (
                    element.getAttribute('data-filter-type') &&
                    element.getAttribute('data-filter-type').toLowerCase().indexOf(this.currentFilterValue) != -1
                ) {
                    element.classList.remove(this.classes.hide);
                } else {
                    element.classList.add(this.classes.hide);
                }
            }
        });

        let event = new CustomEvent('Projects.ContentChanged');
        window.dispatchEvent(event);
    }

    updateThumbnailImage(event) {
        const imageUrl =
            event.target.querySelector('.project-wrapper').dataset.image;

        let images = this.thumbnail.querySelectorAll('img');
        images.forEach((image) => {
            if (image.src === imageUrl) {
                image.classList.add('active');
            } else image.classList.remove('active');
        });
    }

    updateThumbnailCoordinates() {
        // You can tweak this value
        const speed = 0.08;

        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

        this.pos.x += Math.max(
            Math.min(
                // X coordinates
                (this.mouse.x - this.pos.x) * dt,
                // Right limit of the screen minus CTA
                window.innerWidth -
                    this.pos.x -
                    this.thumbnail.clientWidth / 2 -
                    this.ctaWidth -
                    this.containerMargin -
                    20,
            ),
            // Left limit of the screen
            0 - this.pos.x + this.thumbnail.clientWidth / 2,
        );
        this.pos.y += Math.min(
            Math.max(
                // Y coordinates
                (this.mouse.y - this.pos.y) * dt,
                // Top limit of the screen
                0 - this.pos.y + this.thumbnail.clientHeight / 2,
            ),
            // Bottom limit of the screen
            window.innerHeight - this.pos.y - this.thumbnail.clientHeight / 2,
        );

        this.xSet(this.pos.x);
        this.ySet(this.pos.y);
    }

    showThumbnailEvent() {
        gsap.to(this.thumbnail, { opacity: 1, duration: 0.3 });
    }

    hideThumbnailEvent() {
        gsap.to(this.thumbnail, { opacity: 0, duration: 0.3 });
    }

    mouseMoveEvent(event) {
        this.mouse.x = event.x;
        this.mouse.y = event.y;
    }

    resizeUpdates() {
        this.ctaWidth = this.el.querySelector('.arrow-cta')?.offsetWidth;
        this.containerMargin =
            (window.innerWidth -
                this.el.querySelector('.centered-content').offsetWidth) /
            2;
    }
}

Me.views['ProjectsList'] = ProjectsList;
